// topbar menu styles start
@import 'settings/variable';

.topBarImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0px;
    margin-bottom: 10px;
}

.topBarImageAlignment {
    text-align: center;
}

.themeColorText {
    color: #f17038;
}

body a {
    // color: #B3330E;
    text-decoration: none;
    color: #00ae7b;

    &:hover {
        // color: #862308;
        color: #00ae7b;
    }
}


.paginationcss {
    margin-right: 0;
}

.react-select__input {
    height: 24px !important;
}

.iconSize {
    font-size: 13px !important;
}

.cardShadow {
    // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 1s;
    // border: none !important;
    border-radius: 10px;
}

.dropDownPadiing {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.dropDownPadiingAlign {
    padding-top: 12px !important;
    padding-bottom: 22px !important;
}

.jsonUploadFiles {
    padding-bottom: 2.2rem;
}

.progresBarClr {
    background-color: $color-accent !important;
}

.tabRootFirst {
    height: 36px;
    width: 37px;
    background-color: $color-accent;
    border-radius: 18px;
    justify-content: center;
    text-align: center;
    padding: 7px;
    cursor: pointer;
}

.modal-content {
    border: none !important;
}

.modal-title {
    width: 100% !important;
}

.icon_Color {
    color: $color-accent !important;
}

.logoStyle {
    display: flex !important;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 20px;
}

@media screen and (max-width:1024px) {
    .logoStyle {
        display: block !important;
        justify-content: center;
        text-align: center;
        align-items: center;
    }
}

.setMainTabFirst {
    height: 36px;
    width: 37px;
    background-color: #91dbc6;
    border-radius: 18px;
    justify-content: center;
    text-align: center;
    padding: 7px;
    cursor: pointer;
}

.tabRootFirst:hover {
    background-color: #3fe6b6;
}

.tabCenter {
    justify-content: center !important;
}

.bg-inactiveTab {
    height: 36px;
    width: 37px;
    border-radius: 18px;
    justify-content: center;
    text-align: center;
    padding: 7px;
    background-color: #e9ecef !important;
}

.progressCustom {
    height: 3px !important;
    margin-top: 18px !important;
    width: 30% !important;
    border-radius: 0 !important;
}

.dropDownPadiingFields {
    padding-top: 21px !important;
    padding-bottom: 18px !important;
}

hr {
    margin-top: 0 !important;
}

.css-xp4uvy {
    top: 50% !important;
}

.ctPGeG {
    width: 70.6% !important;
}

.labelsBorder {
    border-bottom: none !important;
}

.cardShadowForm {
    // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 1s;
    // border: none !important;
    border-radius: 10px;
}

.textFieldsWidth {
    width: 100% !important;
    height: 30px !important;
    color: "red"
}

.edit_icon svg {
    visibility: hidden;
    opacity: 0;
}

.edit_icon:hover svg {
    visibility: visible;
    opacity: 1;
}

.textFieldsWidth input {
    padding-left: 20px !important;
    // color:red
}

.textFieldsWidthnewTem {
    width: 100% !important;
    height: 15px !important;
}

.ilXUFh {
    border-bottom: none !important;
}

/* The slider itself */
.slider {
    -webkit-appearance: none;
    /* Override default CSS styles */
    appearance: none;
    width: 100%;
    /* Full-width */
    height: 10px;
    /* Specified height */
    background: #d3d3d3;
    /* Grey background */
    outline: none;
    /* Remove outline */
    opacity: 0.7;
    /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s;
    /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    border-radius: 10px;
}

/* Mouse-over effects */
.slider:hover {
    opacity: 1;
    /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    width: 15px;
    /* Set a specific slider handle width */
    height: 18px;
    /* Slider handle height */
    background: #007bff;
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
    border-radius: 40px;
}

.slider::-moz-range-thumb {
    width: 15px;
    /* Set a specific slider handle width */
    height: 18px;
    /* Slider handle height */
    background: #007bff;
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
    border-radius: 40px;
}

.topbarMenu {
    width: 200px;
    border-radius: 0;
    border: none;
    padding-bottom: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;
}

.theme-white {
    border: 1px solid #ddd !important;
    background: white !important;
}

.iconstyle {
    background: #d6d7da !important;
    border: 1px solid #d6d7da !important;
}

// topbar menu styles end
.form input {
    background: white;
    margin-bottom: 0.5rem;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightHoliday {
    background-color: #fbff2b;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightWeekend {
    background-color: #1efd00;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightLessThan6Hrs {
    background-color: #ff0000;
}

body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width: 2em;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-size: 12px;
}

// Dasboard
.dashBoardCardWithScroll {
    height: 350px;
}

//Scrollable table with Sticky Header
.table-fixed tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tr:after {
    content: "";
    display: block;
    visibility: hidden;
    clear: both;
}

.table-fixed tbody td,
.table-fixed thead>tr>th {
    float: left;
}

//kpiTable
.kpiTable {
    background-color: #f17038;
    color: black;
}

.colorLegend {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.list_class {
    padding-bottom: 6px;
    padding-top: 10px;
    font-size: 16px;
}

label {
    display: inline-block;
    // margin-bottom: 0.5rem;
    font-size: 14px;
    // margin-top: 0.5rem;
    color: black;
}

.borderContollerColor {
    border-color: #17a2b8 !important;
}

.bhdLno {
    border-bottom: none !important;
}

.labelMargin {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    text-transform: capitalize;

}

.headingsFont {
    font-size: 20px !important;
}

.headingsFontC {
    font-size: 18px !important;
}

.css-vj8t7z {
    height: auto !important;
}

.css-2o5izw {
    height: auto !important;
}

.addIcon {
    font-size: 14px !important;
}

.controller_label {
    font-size: 16px;
    margin-top: 0.5rem;
    cursor: pointer;
    // overflow: hidden;
    white-space: normal;
    text-overflow: clip;
    padding: 2px;
    text-align: center;
    color: grey;
}

.controller_labelS {
    font-size: 16px;
    margin-top: 0.5rem;
    cursor: pointer;
    // overflow: hidden;
    white-space: normal;
    text-overflow: clip;
    text-align: center;
    color: grey;
}

.w-30 {
    width: 30% !important;
}

.css-1hwfws3 {
    text-transform: capitalize !important;
}

.scrollTop {
    position: fixed;
    width: 100%;
    bottom: 20px;
    align-items: center;
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
    right: 30px;
    background-color: $color-accent;
    border-radius: 27px;
}

.scrollTop:hover {
    opacity: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.5;
    }
}

@media screen and (max-width:786px) {
    h2 {
        font-size: 25px !important;
    }
}

.controllerDelteIConPosNode {
    position: absolute;
    right: 32px;
    top: 31px;
}

.controllerDelteIConPos {
    //position: absolute;
    right: 32px;
    top: 34px;

}

.controllerDelteIConPosnewTem {

    right: 5px;
    top: -5px;
    position: relative;
}

.controller_labelForFields {
    // margin-bottom: 0.5rem;
    font-size: 16px;
    margin-top: 0.5rem;
    cursor: pointer;
    overflow: hidden;
    white-space: normal;
    text-overflow: clip;
    padding: 2px;
    text-align: center;
    color: 'gold';
}

.fieldsHeading {
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: capitalize;
}

.visiBilityHidden {
    visibility: hidden !important;
}

.fieldsHeadingServer {
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: capitalize;
    font-size: 20px !important;
}

.progressCustomTop {
    height: 2px !important;
    margin-top: 0 !important;
    border-radius: 0 !important;
}

.drownArrowC {
    font-size: 22px !important;
}

.downColor {
    color: #cfd5dc !important;
}

.tabsBorder {
    border-top: 1px solid $color-accent;
    border-left: 1px solid $color-accent;
    border-right: 1px solid $color-accent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    border-bottom: none !important;
    color: $color-accent !important;
}

.editIcon {
    width: 15px;
    cursor: pointer;
}

.editIconField {
    width: 15px;
    cursor: pointer;
    position: absolute;
    right: 7px;
    font-size: 13px;
}

.progress {
    height: 28px;
    margin-top: 20px;
}

.progress-bar.active,
.progress.active .progress-bar {
    -webkit-animation: progress-bar-stripes 2s linear infinite;
    -o-animation: progress-bar-stripes 2s linear infinite;
    animation: progress-bar-stripes 2s linear infinite;
}

.progress-bar-striped,
.progress-striped .progress-bar {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    -webkit-background-size: 40px 40px;
    background-size: 40px 40px;
}

.progress-bar {
    float: left;
    width: 0%;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}

.downloadText {
    position: fixed;
    animation: flow linear 15s infinite;
    white-space: nowrap;
    transform: translateX(100%);
}

@keyframes flow {
    0% {
        left: 0%;
        transform: translateX(-20%);
    }

    100% {
        left: 100%;
        transform: translateX(100%);
    }
}

.errorApiText {
    position: absolute;
    left: 329px;
    top: 196px;
    height: 13px;
}

@media screen and (max-width:1024px) {
    .errorApiText {
        position: absolute;
        left: 280px;
        top: 195px;
        height: 13px;
    }
}

@media screen and (max-width:992px) {
    .errorApiText {
        position: static;
        height: 13px;
        padding-left: 20px;
    }
}

.account {
    height: 100%;
    width: 100%;
    min-height: calc(100vh - 100px);
    display: flex;
    overflow-y: none;

    @include themify($themes) {
        background-color: $color-accent;
    }
}

.account__wrapper {
    margin: auto;
    padding-top: 0px;
    padding-right: 23px;
    padding-bottom: 0px;
    padding-left: 23px;
    border: 1px solid $color-accent;
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.account__wrapper1 {
    margin: auto;
    padding: 10px;

    justify-content: center;
}

.textcenter {
    text-align: center;
}

.account__card {
    @include themify($themes) {
        background-color: white;
    }

    background-color: white;
    padding: 5px;
    // max-width: 580px;

    width: 100%;
    //min-width: 800px;
    // margin-left: 10%;
    border-radius: 10px;
}

//color 
.template_color {
    float: left;
    width: 20px;
    height: 20px;
    margin: 5px;
    border: 1px solid rgba(0, 0, 0, .2);
}

.btn {
    background-color: $color-accent;
    // !important;
}

.donothover {
    border: none !important;

    &:before,
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        background-color: unset !important;
        border: none !important;
        border-color: none !important;
        // color:#06f9b4 !important;
    }

}

.pagination {
    display: inline-block;
}

.pagination a {
    color: black;
    float: left;
    padding: 1px 6px;
    text-decoration: none;
}

.pagination a.active {
    color: white;
    border-radius: 3px;
}

.pagination a:hover:not(.active) {
    background-color: #ddd;
    border-radius: 3px;
}

.btn-group .btn {
    padding: 5px 10px !important;
}

.btn.btn-secondary {
    background-color: transparent !important;
}

.p-connected-overlay-enter-done {
    z-index: 20px !important;
}

.modal .p-connected-overlay-enter-done .p-multiselect-header {
    z-index: 20px !important;
}

.modal-dialog {
    max-width: 50%;
}

// .p-multiselect-panel {
//     // width: 100% !important;
// }

.profile-toggle {
    border: none;
    background-color: white !important;
    padding: 2px;
}

.actiondiv {
    border-radius: 4px;
    margin-left: 2px;
    height: 20px;
    width: 20px;
    margin-top: 5px;
}

.actiondiv_grid {
    margin-left: 2px;
    height: 20px;
    width: 20px;
    margin-top: 5px;
    border-top-left-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 4px;
}

.actiondiv_list {
    margin-left: 2px;
    height: 20px;
    width: 20px;
    margin-top: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 0px;
}

.conrollerFont {
    font-size: 14px
}

.textElipses {
    font-weight: 500;
    color: $color-template;
    cursor: pointer;
}

.postionRelative {
    top: 8px;
    position: relative;
}

body .pi {
    font-size: 1em;
}

.multiSelectDropDownCard {
    height: 30px;
    width: 70px;
    cursor: pointer;
    font-size: 14px;
    color: #333333;
    background: #ffffff;
    padding: 0.529em 0.429em;
    border: 1px solid #a6a6a6;
    transition: border-color 0.2s,
        box-shadow 0.2s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 3px;
    position: relative;
    top: 3px;
    text-transform: capitalize;
}

.itemsWarperCard {
    position: absolute;
    width: 155px;
    // height: auto;
    height: 270px;
    overflow-y: auto;
    z-index: 9999;
    color: black;
    border: 1px solid #c8c8c8;
    background-color: #ffffff;
    // box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
}

.multiSelectDropDownCardUl {
    padding: 5px;
    border: 0;
    border-bottom: 1px solid #0000001a;
}

.multiSelectWrapperItems {
    display: flex;
    padding-left: 5px;
    cursor: pointer;
}

.chekcIconAdjust {
    font-size: 14px;
    margin-right: 4px;
    margin: 2px 4px 4px 2px;
}

.chekcIconAdjustLabel {
    margin-top: 4px;
}

.p-inputgroup {
    height: 36px !important;
}

.cardForListMargin {
    margin: 7px !important;
}

.cardForGridMargin {
    margin: 7px 7px 0 7px !important;
}

.mobileScreenHaederView {
    position: relative;
    top: 8px;
}

.color-picker__button {
    padding: 10px;
}

//  .MuiIconButton-label-6{
//      margin-bottom: 8px !important;
// height: 20px !important;
//  }
//   &.btn-primary, &.btn-success, &.btn-outline-secondary, &.btn-secondary,
//   &.btn-outline-primary, &.btn-outline-danger, &.btn-outline-warning, &.btn-outline-success,
//   &.icon, &.icon.btn-secondary {

//     &.disabled {
// background-color: grey;
//     }}

.btn-success.disabled,
.btn-success:disabled {
    background-color: grey !important;
}

.text-normal {
    font-size: 1rem !important;
}

.textTransForm {
    text-transform: capitalize !important;
}

.detailsImgStyle {
    object-fit: contain;
    max-height: 60px;
}

.card-img-top {
    margin-top: 10px;
    height: 50px;
    object-fit: contain;
}

.p-dropdown-item.p-highlight {
    background-color: #00ae7b !important;
    background: #00ae7b !important;
    color: #fff;
}

.p-dropdown-panel {
    width: 200px;
    position: absolute;
    right: 0px;
    z-index: 1;
}

.svg-inline--fa .fa-search-minus .fa-w-16 .mr-3 {
    margin-right: 4px;
}

.p-dropdown {
    width: 200px;
}

.sample-actions {
    padding: 6px 15px;
    border-radius: 5px;
}

.setting-draw {
    position: fixed;
    right: 0;
    top: 50vh;
    z-index: 9;
    padding: 5px 15px;
    border-radius: 0;
    width: 55px;
    margin: 0;
    // background-color: white !important;
    // color: #b3330e !important;
    // border-color: #b3330e !important;
}

.custom_file_uploader {
    color: #b3330e;
    font-weight: 500;
    cursor: pointer;
}

.custom-uploader {
    border: 1px solid #b3330e;
    border-radius: 5px;
    padding: 5px 25px;
    color: #b3330e;
    font-weight: 500;
    cursor: pointer;
}

#logoUploader,
#favUploader,
#backgroundUploader,
#login-img-uploader {
    display: none;
}

.setting_heading_l2 {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: bold;
    padding-bottom: 0.8rem;
}

.settings_img_sample {
    max-height: 3rem;
    width: 100%;
    object-fit: contain;
}

.form-check-input:checked,
.form-check-input::before {
    color: #b3330e !important;
    background-color: #b3330e !important;
}

.p-multiselect {
    width: 100%;
}

.input-color-picker {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: $color-template;
    border: 1px #cfcece solid;
    box-shadow: 2px 2px 5px #cfcece;
    cursor: pointer;
}

#input-color-input {
    opacity: 0;
}

.setting-panel {
    height: 100vh;
    z-index: 999;
    width: 30rem;
    position: fixed;
    top: 0;
    right: 0;
    display: block;
}

.settings-panel-active {
    transition: transform .4s cubic-bezier(.05, .74, .2, .99);
    transform: translate(0);
}

.settings-panel-hide {
    transition: transform .4s cubic-bezier(.05, .74, .2, .99);
    transform: translate(100%);
}

.settings-button {
    position: fixed;
    right: 30rem;
    z-index: 9;
    margin: 0;
    border-radius: 0;
    top: 50vh;
    display: block;
    height: 3rem;
    width: 3rem;
    border: 1px solid #cfcece;

    &:focus {
        box-shadow: none !important;
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

.loginTemp,
.regTemp {
    height: calc(100vh - 480px);
    width: 100%;
    background-color: lightcyan;
    display: flex;
    padding: 1rem;
    background: no-repeat contain;
    overflow: auto;

    label {
        font-size: 10px;
        margin-bottom: 0.2rem;
    }

    .form-group {
        margin-bottom: 0.5rem !important;
    }

    .login-wrap {
        margin: auto;
    }
}

.regTemp {
    height: calc(100vh - 430px);
    justify-content: center;
    align-items: center;
}

.login2 {
    background-color: white;
    height: 98%;
    margin: auto;

    .log-img {
        display: flex;
        max-height: 100%;
        width: 60%;
        justify-content: center;
        padding: 1rem;

        img {
            height: 100%;
            // width: unset;
            object-fit: contain;
        }
    }

    .log-form {
        flex-grow: 1;

        form {
            min-width: 85%;
        }
    }
}

.login5 {
    height: 100%;
    width: 100%;
}

.proj-name-color {
    color: #b3330e;
}

.template-page-name {
    text-transform: capitalize;
    float: left;
    color: rgb(60, 97, 119);
    font-weight: bold;
    align-self: center;
}

.login-layout-logo {
    height: 30px;
    object-fit: contain;
}

.login4 {
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    margin: auto;

}

.login4,
.login5 {
    label {
        font-size: small;
        margin-bottom: 0.2rem;
    }

    .form-group {
        margin-bottom: 0.5rem !important;
    }
}

.login-scale {
    transform: scale(0.5);
}

.newproj-wrapper {
    margin: 0 -1.5rem;
    margin-top: -29px;

    .account {
        background-color: white !important;
        min-height: calc(100vh - 61px);
    }

    .account__wrapper {
        padding-left: unset;
        padding-right: unset;
        margin: unset;
        border-radius: 0px;
        border: none;
    }
}

.settings-auth-label {
    position: absolute;
    top: -3px;
}

.h-100 {
    height: 100% !important;
}

.bg-default {
    background-color: $color-accent;
}

.tableCardBody-in {
    padding: 10px 0px !important;
}

.border-0 {
    border: none !important;
}

.proj-card-button {
    .btn {
        padding: 0.375rem 0.75rem !important;

        svg {
            margin: unset;
        }
    }
}

.menu-li {
    display: block;
    width: 100%;
    position: relative;
    float: none;
    padding: 0;
    border-bottom: 1px solid rgba(36, 45, 58, .5);
    box-shadow: 0 1px #ffffff1a;
}

.menu-item {
    display: block;
    height: 42px;
    padding-left: 18px !important;
    padding-top: 0 !important;
    text-shadow: none;
    font-size: 13px;
    letter-spacing: .02em;
    text-decoration: none;
    color: #fff !important;
    line-height: 42px;
    white-space: nowrap;
    overflow: hidden;
    transition: all .2s ease;
}

.background-filter {
    backdrop-filter: brightness(0.9);
}

.p-unset {
    padding: unset !important;

}

.m-unset {
    margin: unset !important;
}

.side-menu::-webkit-scrollbar {
    width: 0.5rem;
}

.side-menu::-webkit-scrollbar-track {
    background-color: #bfbfbf;
}

.side-menu::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px #6a6a6a;
    background-color: #6a6a6a;
}

.add-new-screen {

    input,
    textarea {
        // width:97% !important;
        margin-bottom: 0 !important;
    }
}

.checkbox-btn__label {
    padding-top: 0px !important;
}

.letter-icon {
    padding: 8px;
    font-size: 8px;
    text-transform: uppercase;
    z-index: 9999;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.925) !important;
}

.container__wrap {
    background-color: rgb(76 225 182 / 14%) !important;
    // background-color: #fff;
}

.proj-card-dash {
    z-index: 1;
    border: none;

    // box-shadow: rgb(0 3 61 / 10%) 0px 2px 2px;
    // box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1), 0 6px 8px rgba(0, 0, 0, 0.1);
}

.cardStyle {
    height: 20px;
    bottom: 17px;
    z-index: 0;
    background-color: #34f9be;
}

.preview-card-wrap {
    height: calc(100% - 50px);
    overflow: auto;

}

.table-height {
    max-height: calc(100vh - 480px);
}

.preview-page-height {
    height: calc(100vh - 290px);
}

.editor-cotainer {
    height: calc(100% - 70px);
}

.editor-bar {
    min-height: 50vh;
}

.select-ce-button {
    padding: 1rem;
    border: 2px solid #4ce1b6;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    font-size: 1rem;

    &:hover {
        background: #4ce1b6;
    }
}

.select-ce-files {
    margin-bottom: 0.8rem;
    padding: 0.2rem;
    font-size: 16px;
    text-align: center;
    text-transform: capitalize;
    cursor: pointer;
    border: 1px solid #4ce1b6;
    border-radius: 5px;

    &:hover {
        background: #4ce1b6;
    }
}

.selected-file {
    background: #00fdb3;
    font-size: 90%;
    font-weight: bold;
}

.logo-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.topbar-logo {
    height: 90%;
    object-fit: contain;
    width: unset !important;
    cursor: pointer;
}

.customBtns .cancelLoginBtnStyle {
    border-color: #4ce1b6;
    color: #6c757d;
}


.customBtn .cancelMenuBtnStyle {
    border-color: #3c6177;
    color: #3c6177;
}


.wid_100,
.wid_100 .p-inputtext {
    width: 100%;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.prime_custom_btn {
    position: relative;
}

.full_width {
    width: 100%;
}

.logoupload {
    position: absolute;
    top: 0;
    opacity: 0;
}

.w-full {
    width: 100%;
}

.p-password .p-inputtext {
    width: 100%;
}

.color-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.color-wrapper .color-input {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 20%;
    background-color: transparent;
    border: 1px solid #cfcece;
    box-shadow: 2px 2px 5px #cfcece;
    pointer-events: none;
    margin: 0.25rem;
}

.applyBtn {
    margin-top: 10px;
    width: 100%;

    .btn {
        width: 100%;
    }
}

.colorPickers .p-overlaypanel-content {
    padding: 5px;
}

.p-sidebar .p-sidebar-content {
    padding-top: 1rem;
}

.settingSwitch {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.d-flex .flex,
.d-flex .addOption,
.d-flex .setting {
    flex: 1;
}

.p-dropdown-label.p-inputtext {
    padding: 0.4rem;
}

.p-inputtext {
    padding: 0.6rem;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 0.5rem 1.5rem;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #4ce1b6;
    background: #4ce1b6;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #4ce1b6;
    background: #4ce1b6;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #00ae7b;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #00ae7b;
    background: #00ae7b;
    color: #ffffff;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background-color: $color-template
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #00ae7b;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: #00ae7b;
    background: #00ae7b;
    color: #ffffff;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: none;
    border-color: #4ce1b6;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: #4ce1b6;
}

.p-togglebutton.p-button.p-highlight {
    background: #4ce1b6;
    border-color: #4ce1b6;
    color: #ffffff;
}

.p-togglebutton.p-button.p-highlight:hover {
    background: #00ae7b;
    border-color: #00ae7b;
    color: #ffffff;
}

.p-togglebutton.p-button {
    padding: 5px 14px;
}

.float-right {
    float: right;
}

.form-label {
    margin-bottom: 2px;
}

.p-multiselect .p-multiselect-label {
    padding: 5px 10px;
}

.side-menu a {
    color: #fff;
    text-decoration: none;
}

.p-inputnumber {
    width: 100%;
}

// .nobtnmargins .btn {
//     margin: 0;
// }

.table-responsive_width {
    max-width: 1320px;
}

.modal-backdrop {
    --bs-backdrop-opacity: 0.3;
}

.p-password .p-icon {
    right: 10px;
    /* Adjust this value as needed */
    top: 50%;
    transform: translateY(-50%);
}

.p-timeline-event-opposite {
    display: none;
}

.p-dialog-header {
    background-color: $color-accent !important;
    color: white !important;
    height: 3.5rem !important;
    text-align: left;
    padding: 0.5rem;
}

.p-dialog .p-dialog-header-icons{
    display: none;
}

.new-project-name:enabled:focus {
    box-shadow: 0 0 0 0.2rem #a1d7c782;
    border-color: #00ae7b;
}

.new-project-name:enabled:hover {
    border-color: #00ae7b;
}

.new-project-name-danger:enabled:focus {
    box-shadow: 0 0 0 0.2rem #ffc3c396;
    border-color: red;
}

.new-project-name-danger:enabled:hover {
    border-color: red;
}

.p-inputtext-lg{  // input field in the copy project modal
    width: 50%;
}

.copy-yes-btn:disabled { // yes button in the copy project modal
    background-color: #4ce1b6 !important;
}

.validate-text-danger{
    color: red;
    font-size: 9px;
    float: right;
    margin-top: 5px;
    margin-right: 5px;
}